@import "./items/index";
@import "./variables";
@import "./forms";
@import "../fonts/stylesheet.css";

body {
  width: 100%;
  height: 100%;
  min-height: 100vh;
  margin: 0;
  font-family: $global-font-family;
  color: $text-color;
  background-color: $main-background-color;
}

* {
  box-sizing: border-box;

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: #5e5e5e;
    border-radius: 8px;
  }
}

a {
  text-decoration: none;

  &:link {
    color: $blue;
  }
}

button {
  font-family: $global-font-family;
}

.custom-link a {
  font-size: $font-size-normal;
  font-weight: 400;
  color: $accent-color2;
  text-decoration: none;
}

.clearfix::after {
  display: table;
  clear: both;
  content: "";
}

.clearfix {
  overflow: auto;
}
