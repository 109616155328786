.registration-form {
  &__name {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .block {
    width: 100%;
  }

  .block:first-child {
    margin-right: 10px;
  }

  .block:last-child {
    width: 100%;
    margin-left: 10px;
  }
}
