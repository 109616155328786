@font-face {
  font-weight: 400;
  font-family: "Poppins";
  font-style: normal;
  src: url("Poppins-Regular.ttf") format("truetype");
}

@font-face {
  font-weight: 600;
  font-family: "Poppins";
  font-style: normal;
  src: url("Poppins-SemiBold.ttf") format("truetype");
}

@font-face {
  font-weight: 700;
  font-family: "Poppins";
  font-style: normal;
  src: url("Poppins-Bold.ttf") format("truetype");
}
